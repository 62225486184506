.Welcome {
  font-size: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;

  #and {
    margin-left: 4%;
    color: var(--secondary-color);
    font-weight: bold;
  }
}
.hello {
  border: 1px solid black;
  display: flex;
  border-radius: 3px;
  max-width: 500px;
  padding: 10px;
  box-shadow: 3px 3px 2px var(--light-color);
  font-weight: bold;
  font-size: 50px;
  margin-bottom: 2%;
  margin-left: 4%;
  margin-top: 1%;

  @media (max-width: 500px) {
    font-size: 25px;
    width: 50%;
    margin-top: 3%;
  }
}
.iam-typewriter {
  border: 1px solid black;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 3px 3px 2px var(--light-color);
  font-weight: bold;
  font-size: 50px;
  width: 550px;
  margin-left: 7%;
  span {
    color: var(--third-color);
  }

  @media (max-width: 500px) {
    font-size: 25px;
    width: 50%;
    margin-top: 3%;
  }
}
.social {
  display: grid;
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 5px;
  img {
    width: 48px;
  }
}
#profile-main {
  display: grid;
  position: absolute;
  bottom: 5%;
  right: 0;
  margin-right: 5px;
  border-radius: 40% 15% 15% 15%;
  height: 250px;
  transform: rotate(-90deg);

  @media (max-width: 500px) {
    height: 200px;
    bottom: 100%;
    display: grid;
    position: absolute;
    top: 70%;
    right: 0;
    margin-right: 5px;
  }
}
.download-cv {
    width: 175px;
    background-color: transparent;
    border-radius: 28px;
    border: 1px solid #5a66a9;
    cursor: pointer;
    color: #5a66a9;
    font-size: 17px;
    padding: 1%;
    text-decoration: none;
    font-weight: bold;
    margin: 3% 0 0 7%;

  &:hover {
    background-color: var(--secondary-color);
    color: #fff;
  }
}
