.About {
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-weight: 500;
  flex-direction: column;

  @media (max-width: 500px) {
    margin: 0 4% 0 4%;
  }
}
.picture-box {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  img {
    border-radius: 50%;
    height: 200px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  }
  @media (max-width: 500px) {
    justify-content: space-evenly;
    img {
      height: 100px;
      margin-top: 3%;
      align-items: center;
    }
  }
}
.about-box {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 1%;
  flex-direction: column;

  div {
    flex-direction: column;
    margin: 1%;

    h3 {
      margin-top: 20px;
      display: flex;
    }
    p {
      margin-top: 1%;
      width: 100%;
      font-weight: 300;
    }
    #caption {
      font-weight: 100;
    }
  }
  img{
    height: 100px;
  }
  .img-sub{
    height: 100px;
    border-radius: 50%;
  }
}
