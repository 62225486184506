@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Gujarati:wght@100;200;300;400;700&display=swap');

:root {
  --font-base: 'Noto Sans Gujarati', sans-serif;
  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --third-color: #023e8a;
  --light-color: #ade8f4;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-base);
}
