.Navbar {
  position: sticky;
  top: 0;
  background:white;
}
.app_navbar {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  box-shadow: var(--third-color) 0px 10px 20px -20px;
  position: relative;
  width: 100%;
  height: 100px;
  margin-top: 3%;
  text-transform: uppercase;

  .links {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding-left: 10%;
    position: absolute;

    li {
      list-style: none;
      color: var(--gray-color);
      position: relative;

      :hover{
        font-size: 23px;
        transition: all transform 1s;
      }

      :after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #023e8a;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      :hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      :visited {
        color: inherit;
      }
      a {
        text-decoration: none;
        color: inherit;
      }
    }
    
  }
  @media screen and (max-width: 500px) {
   .links{
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    li{
      :hover{
        font-size: 17px;
      }
      :after {
        display: none;
      }
    }
   }
  }
}
.logo{
    img{
      height: 15vw;
      position: relative;
      left: -10px;
      top: -45px
    }
    @media screen and (max-width: 700px){
      img{
        height: 100px;
        position: absolute;
        top: -10px;
        left: -10px;
      }
    }
    @media screen and (max-width: 270px){
      display: none;
    }

}
.nav-container{
  display: flex;
  flex-direction: row;
}